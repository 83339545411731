import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";

import { environment } from "../../environments/environment";
import swal from "sweetalert";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BmsapiService {
  apiUrl: string;
  BaseUrlCrm: string;

  private messageSource = new BehaviorSubject({ IsLogged: "FALSE" });
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) {
    // console.log('API Calling...'+ environment.apiUrlBmsBase );
    this.apiUrl = environment.apiUrlBmsBase;
    this.BaseUrlCrm = ""; //environment.BaseUrlCrm;


    if (this.GetUserData("Code") == "SIBAdmin") {
      //this.apiUrl = 'https://crm.squareinsurance.in/backuplivelife-copy/api';
      this.apiUrl = 'https://api.policyonweb.com/copy-api/v1';
    }

    //alert(this.apiUrl);
    
  }

  changeMessage(message: any) {
    this.messageSource.next(message);
  }

  public IsLoading() {
    this.spinner.show();
  }
  public HideLoading() {
    this.spinner.hide();
  }

  public ErrorMsg(msg) {
    swal("Warning!", msg, "warning");
  }
  public ToastMessage(msg) {
    swal("success!", msg, "success");
  }

  public getHeader() {
    let api_key = this.GetUserData("JwtToken");

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${api_key}`,
      }),
    };

    return httpOptions;
  }

  public Call(paramsNames) {
    const index = paramsNames.indexOf("?");
    var other_prams = "";
    if (index !== -1) {
      //alert(`The ? mark is found at index ${index}.`);
      other_prams = "&source=crm";
    } else {
      //alert("The ? mark is not found in the string.");
      other_prams = "?source=crm";
    }

    return new Promise((resolve, reject) => {
      this.http
        .get(this.apiUrl + "/" + paramsNames + other_prams, this.getHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  public Call_2(paramsNames) {
    return new Promise((resolve, reject) => {
      this.http.get(paramsNames).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  public Call3(paramsNames) {
    return new Promise((resolve, reject) => {
      this.http.get(this.BaseUrlCrm + "/" + paramsNames).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public Call4(urls, paramsNames) {
    // this.apiUrl="https://api.policyonweb.com/API/v1";

    return new Promise((resolve, reject) => {
      this.http
        .get(this.apiUrl + "/" + urls + "" + paramsNames, this.getHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  public HttpPostType(apiName, data) {
    const index = apiName.indexOf("?");
    var other_prams = "";
    if (index !== -1) {
      //alert(`The ? mark is found at index ${index}.`);
      other_prams = "&source=crm";
    } else {
      //alert("The ? mark is not found in the string.");
      other_prams = "?source=crm";
    }

    return new Promise((resolve, reject) => {
      this.http
        .post(this.apiUrl + "/" + apiName + other_prams, data, {})
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });

    /*
	 return new Promise((resolve, reject) => {
		this.http.post(this.apiUrl+'/'+apiName,data,requestOptions)
		  .subscribe(res => {
			resolve(res);
			  
		  }, (err) => {
			reject(err);
			 
		  });
	  }); 
	  */
  }

  public HttpPostType1(apiName, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.BaseUrlCrm + "/" + apiName, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public HttpPostTypeCrm(apiName, data) {
    return new Promise((resolve, reject) => {
      this.http
        .post("http://13.127.142.101/sanity/ci/crmdev/api/" + apiName, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  public Toast(Type, Message) {
    alert(Message);
  }

  public HttpPostTypeProxy(apiName, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/" + apiName, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public HttpForSR(Type, apiName, data) {
    if (Type == "post") {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + "/crm/" + apiName, data).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + "/crm/" + apiName).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      });
    }
  }

  public GetUserData(key) {
    const data = JSON.parse(localStorage.getItem("UserData"));
    if (data != null) {
      return data[key];
    } else {
      return "FALSE";
    }
  }
  public GetUserId() {
    if (this.GetUserData("Logged_In") == "FALSE") {
      return 0;
    } else {
      return this.GetUserData("User_Id");
    }
  }

  public GetIs_Remember(key) {
    const data = JSON.parse(localStorage.getItem("Is_Remember_Data"));
    if (data == null) {
      return 0;
    } else {
      return data[key];
    }
  }

  public StandrdToDDMMYYY(d) {
    return d;
    // console.log(d);
    // console.log(new Date(d));
    if (d == "" || d == null || d == "00-00-0000") {
      return "";
    } else {
      d = new Date(d);
      return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate(); // + ' '+d.toString().split(' ')[4];
      //return d.getDate() + "-"+(d.getMonth()+1) +"-"+d.getFullYear();
      // for time part you may wish to refer http://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
    }
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
