import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-raise-claim',
  templateUrl: './raise-claim.component.html',
  styleUrls: ['./raise-claim.component.css']
})
export class RaiseClaimComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
