import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "../../providers/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DataTableDirective } from "angular-datatables";
import { CKEditorComponent } from "ckeditor4-angular";

@Component({
  selector: "app-gems-remark",
  templateUrl: "./gems-remark.component.html",
  styleUrls: ["./gems-remark.component.css"],
})
export class GemsRemarkComponent implements OnInit {
  gemsRemark: FormGroup;

  isSubmitted = false;
  loadAPI: Promise<any>;
  id: any;
  pid: any;
  status: any;
  actionType: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<GemsRemarkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = this.data.id;
    this.pid = this.data.pid;
    this.status = this.data.status;
    this.actionType = this.data.actionType;

    this.gemsRemark = this.formBuilder.group({
      remark: ["", Validators.required],
    });
  }

  ngOnInit() {}

  get formControls() {
    return this.gemsRemark.controls;
  }

  submit() {
    this.isSubmitted = true;
    if (this.gemsRemark.invalid) {
      return;
    } else {
      var fields = this.gemsRemark.value;

      const formData = new FormData();

      formData.append("user_id", this.api.GetUserData("Id"));
      formData.append("user_type", this.api.GetUserType());

      formData.append("id", this.id);
      formData.append("pid", this.pid);
      formData.append("status", this.status);
      formData.append("remark", fields["remark"]);
      formData.append("ActionType", this.actionType);

      // console.log(formData);

      this.api.IsLoading();
      this.api.HttpPostType("GemsWallet/GemsRemark", formData).then(
        (result) => {
          this.api.HideLoading();
          // console.log(result);
          if (result["status"] == 1) {
            this.api.Toast("Success", result["msg"]);
            this.CloseModel();
          } else {
            const msg = "msg";
            this.api.Toast("Warning", result["msg"]);
          }
        },
        (err) => {
          // Error log
          // // console.log(err);
          this.api.HideLoading();
          const newLocal = "Warning";
          this.api.Toast(
            newLocal,
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
          //this.api.ErrorMsg('Network Error :- ' + err.message);
        }
      );
    }
  }

  CloseModel(): void {
    this.dialogRef.close({
      Status: "Model Close",
    });
  }
}
