import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ShareUrlComponent } from "../../modals/share-url/share-url.component";
import { ApiService } from "../../providers/api.service";
import { SocketioService } from "../../providers/socketio.service";
import { PusherService } from "../../providers/pusher.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ForgotPasswordComponent } from "src/app/account/forgot-password/forgot-password.component";
import $ from "jquery";
import { GetRmDetailsComponent } from "../../modals/get-rm-details/get-rm-details.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  CountNotifications: any = 0;
  NotificationsAr: any = [];
  myWindow: any;
  LoginUserId: any = 0;
  HeaderStatus: number = 0;
  HeaderLifeTrainngLabel: any = "Start to Life Training";
  LoginType: string;
  PosStatus: any;
  Life_Training_Status: any;
  UserTypesView: string;
  ChangePassword: any;
  GemsStatus: any;
  PrimeStatus: any;
  currentUrl: any;
  urlSegment: string;
  urlSegmentRoot: any;
  urlSegmentId: any;
  urlSegmentSub: any;
  CopyPasteRights: number;
  LoginId: any;
  FetchData: any;
  RmData: any;
  TeleRmData: any;
  ShowGemsLabels: boolean = false;
  UserLoginType: any;
  DataArr: any;
  ProfileImage: any;
  ShareUrlDATAaRR: any;
  DepartmentIdLoginUser: any;
  CheckPartnerEmitraOrOther: any = "other";

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    public api: ApiService,
    public socketService: SocketioService,
    private pusherService: PusherService,
    public dialog: MatDialog,

    private router: Router
  ) {
    this.LoginUserId = this.api.GetUserData("Id");
    this.GetNotifications(0);
    this.CopyPasteRights = 0;
    this.DepartmentIdLoginUser = this.api.GetUserData("department");

    this.UserLoginType = this.api.GetUserData("Type");
    //alert(window.location.pathname);

    // if(window.location.pathname == "/Agent/ExamStart/TGlmZQ%3D%3D" || window.location.pathname == "/Agent/Training/TGlmZQ%3D%3D") {
    // 	this.HeaderStatus = 0;
    // }
    // else{//else

    // var splitted = this.router.url.split("/");

    this.LoginType = this.api.GetUserType();
    // alert(this.LoginType);
    this.ChangePassword = 0;

    if (this.LoginType == "agent") {
      //agent

      this.CheckGemsRewards();
      this.PosStatus = this.api.GetUserData("pos_status");
      this.Life_Training_Status = this.api.GetUserData("Life_Training_Status");
      this.PrimeStatus = this.api.GetPrimeStatus();
      this.GemsStatus = this.api.GetGemsStatus();

      // alert(this.GemsStatus);
      // alert(this.PrimeStatus);
      // this.ChangePassword = 1;

      if (this.PosStatus == "2" && this.Life_Training_Status == 0)
        this.CheckStatusLife();

      this.CheckPartnerEmitraOrOther = this.api.GetUserData("Partner");
    }

    //agent
    //}

    if (this.LoginType == "employee") {
      this.ChangePassword = 1;
    }

    // if(this.urlSegment =='business-reports' ||  this.urlSegment =='policy-issuance-reports' || this.urlSegment =='manage-renewal-request' ||  this.urlSegment =='earning-reports'){
    //   this.api.DataRightsNavigation('Rights');
    // }else{
    //   this.api.DataRightsNavigation('Default');
    // }
    // let num = [7, 8, 9];
    // num.forEach(function (value) {
    // this.ppp(value);
    // });
  }
  // ppp(value){
  // // alert(value);
  // }

  CheckGemsRewards() {
    var Ids = this.api.GetUserData("agent_id");
    var Roles = this.api.GetUserType();

    this.api.IsLoading();
    this.api.HttpGetType("PrimeAgent/CheckGems/" + btoa(Ids)).then(
      (result) => {
        this.api.HideLoading();

        if (result["Status"] == true) {
          this.ShowGemsLabels = false;
        } else {
          this.ShowGemsLabels = true;
        }
      },
      (err) => {
        this.api.HideLoading();
        this.api.Toast(
          "Warning",
          "Network Error : " + err.name + "(" + err.statusText + ")"
        );
      }
    );
  }

  CopyText(inputElement) {
    this.api.CopyText(inputElement);
    // navigator.clipboard.writeText(inputElement);
  }

  ngOnInit() {
    this.showProfile();

    // this.StopCutCopyPaste();
    // alert(this.currentUrl );

    // this.socketService.setupSocketConnection();

    // this.socketService.ReceiveMessage().subscribe((res: any) => {

    //     // console.log(res);
    //     if(res.Receiver_Id === this.api.GetUserData('Id') ){

    //       this.CountNotifications += 1;
    //       this.api.Toast('Notification',res.Message);
    //     }

    //   });

    /*
    this.pusherService.channel.bind('new-notifictions', data  => {
       var res = JSON.parse(data.Message);
       if(res.Receiver_Id === this.LoginUserId ){
        this.CountNotifications += 1;
        this.api.Toast('Notification',res.Title);
        this.playAudio();
       }

      });
    */

    this.api.TargetComponent.subscribe(
      (page) => {
        if (page != "AppComponent") {
          this.CountNotifications += 1;
          this.playAudio();
        }
      },
      (err) => {}
    );
  }

  CheckStatusLife() {
    var Ids = this.api.GetUserData("Id");
    var Roles = this.api.GetUserType();

    this.api.IsLoading();
    this.api
      .HttpGetType(
        "TrainingExam/CheckLifeTrainingStatus/" + btoa(Ids) + "/header"
      )
      .then(
        (result) => {
          this.api.HideLoading();

          if (result["Status"] == true) {
            if (result["Training_Stage"] == "Complete") {
              this.HeaderStatus = 0;
              this.HeaderLifeTrainngLabel = "";
            } else if (result["Training_Stage"] == "Exam") {
              this.HeaderStatus = 1;
              this.HeaderLifeTrainngLabel = "Contiune to exam";
            } else if (result["Training_Stage"] == "Training") {
              this.HeaderStatus = 1;
              this.HeaderLifeTrainngLabel = "Contiune to training";
            }
          } else {
            this.api.Toast("Warning", result["Msg"]);
          }
        },
        (err) => {
          this.api.HideLoading();
          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  showProfile() {
    this.api
      .HttpGetType(
        "Profile/GetProfile?User_Id=" +
          this.api.GetUserData("Id") +
          "&User_Type=" +
          this.api.GetUserType()
      )
      .then(
        (result: any) => {
          if (result["status"] == true) {
            this.DataArr = result["Data"];

            this.ProfileImage = this.DataArr.profile;
            console.log(this.ProfileImage);
          }
        },
        (err) => {
          this.api.HideLoading();
          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  ClicksLifeTraining(Types: any) {
    if (Types == "training" || Types == "")
      this.route.navigate(["Agent/Training/" + btoa("Life")]);
    else if (Types == "exam")
      this.route.navigate(["Agent/ExamStart/" + btoa("Life")]);
  }

  playAudio() {
    var notification_sound = new Audio("assets/audio/notification-sound.wav");
    notification_sound.play();
  }

  GetNotifications(Type) {
    //alert();
    if (Type == 0) {
      //this.api.IsLoading();
      this.api
        .HttpGetType(
          "Chat/GetNotifications?User_Id=" +
            this.api.GetUserData("Id") +
            "&Type=" +
            Type
        )
        .then(
          (result) => {
            //this.api.HideLoading();

            if (result["status"] == 1) {
              this.NotificationsAr = result["data"];
              this.CountNotifications = result["UnSeenCount"];
            } else {
              //alert(result['message']);
              //this.api.Toast('Warning',result['msg']);
            }
          },
          (err) => {
            // Error log
            //// console.log(err);
            //this.api.HideLoading();
            this.api.Toast(
              "Warning",
              "Network Error : " + err.name + "(" + err.statusText + ")"
            );
            //this.api.ErrorMsg('Network Error :- ' + err.message);
          }
        );
    } else if (Type == 1) {
      // seen notifictions

      this.api
        .HttpGetType(
          "Chat/GetNotifications?User_Id=" +
            this.api.GetUserData("Id") +
            "&Type=" +
            Type
        )
        .then(
          (result) => {
            //this.api.HideLoading();
            this.CountNotifications = 0;
            this.NotificationsAr = result["data"];
          },
          (err) => {
            this.api.Toast(
              "Warning",
              "Network Error : " + err.name + "(" + err.statusText + ")"
            );
            //this.api.ErrorMsg('Network Error :- ' + err.message);
          }
        );
    }
  }

  BackToPanel() {
    var User_Id = this.api.GetUserData("Id");
    var User_Type = this.api.GetUserType();

    if (User_Type == "sp" || User_Type == "user" || User_Type == "agent") {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href =
        "https://www.squareinsurance.in/Agents/check/" +
        btoa(User_Id) +
        "/" +
        btoa(User_Type);
      a.click();
    } else if (User_Type == "employee") {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href =
        "https://www.squareinsurance.in/Prequotes/SetSessionEmployee/BackToPanel/" +
        btoa(User_Id);
      a.click();
    }
  }

  BackToEmployeePanel() {
    var User_Id = this.api.GetUserData("Id");
    var User_Type = this.api.GetUserType();

    if (User_Type == "employee") {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href =
        "https://crmapi.squareinsurance.in/admin/admin_login/employee_login?name=" +
        btoa(User_Id);
      a.click();
    }
  }

  Logout() {
    // this.api.IsLoading();
    // localStorage.removeItem('LoginType');
    // localStorage.removeItem('Token');
    // localStorage.removeItem('UserData');
    // localStorage.setItem('Logged_In', 'FALSE');
    // this.api.changeMessage({IsLoggedIn :"FALSE"});

    let a = document.createElement("a");
    a.target = "";
    a.href = this.api.ReturnWebUrl() + "/agents/logout/crm";
    a.click();
  }

  ShareUrl() {
    const formData = new FormData();

    formData.append("User_Id", this.api.GetUserData("Id"));
    formData.append("UserCode", this.api.GetUserData("Code"));
    formData.append("UserType", this.api.GetUserType());

    this.api.IsLoading();
    this.api.HttpPostType("Auth/ShareUrl", formData).then(
      (result: any) => {
        this.api.HideLoading();

        if (result["status"] == true) {
          //this.CloseModel();
          // console.log(result['Data']);

          this.ShareUrlDATAaRR = result["Data"];
          //this.PostingData = result['PostingData'];

          //this.api.Toast('Success',result['Message']);
        } else {
          this.api.Toast("Warning", result["Message"]);
        }
      },
      (err) => {
        // Error log
        this.api.HideLoading();
        //// console.log(err.message);
        this.api.Toast("Warning", err.message);
      }
    );
  }

  // ShareUrl() {
  //   const dialogRef = this.dialog.open(ShareUrlComponent, {
  //     width: "auto",
  //     height: "auto",
  //     disableClose: true,
  //     data: {},
  //   });

  //   dialogRef.afterClosed().subscribe((result:any) => {});
  // }

  ForgetPassword(LoginType: any, ActionType: any) {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: "30%",
      height: "auto",
      data: { LoginType: LoginType, Action: ActionType },
    });

    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  ClickMyRmTeleRm() {
    var User_Id = this.api.GetUserData("Id");
    var User_Type = this.api.GetUserType();
    const dialogRef = this.dialog.open(GetRmDetailsComponent, {
      // width: "50%",
      // height: "50%",

      width: "auto",
      height: "auto",
      disableClose: true,

      // disableClose: true,
      data: { User_Id: User_Id, User_Type: User_Type },
    });

    dialogRef.afterClosed().subscribe((result: any) => {});
  }
}
