import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../providers/api.service";

import { MatDialog } from "@angular/material/dialog";
import { GemsDetailsViewRemarkComponent } from "../gems-details-view-remark/gems-details-view-remark.component";

@Component({
  selector: "app-viewgemsdetailspopup",
  templateUrl: "./viewgemsdetailspopup.component.html",
  styleUrls: ["./viewgemsdetailspopup.component.css"],
})
export class ViewgemsdetailspopupComponent implements OnInit {
  Agent_Id: any;
  DataAr: any = [];
  Session_Year: any;
  ShowType: any;
  ActionForm: FormGroup;
  dropdownSettingsingleselect: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    itemsShowLimit: number;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
  };
  SR_Session_Year: any;
  isSubmitted: boolean;
  colspanss: any = 5;
  UrlCalls: string = "";

  constructor(
    public dialogRef: MatDialogRef<ViewgemsdetailspopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public api: ApiService,
    public dialog: MatDialog
  ) {
    this.dropdownSettingsingleselect = {
      singleSelection: true,
      idField: "Id",
      textField: "Name",
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true,
    };

    this.Agent_Id = this.data.Id;
    this.Session_Year = this.data.FinancialYear;
    this.ShowType = this.data.Type;

    this.ActionForm = this.formBuilder.group({
      FinancialYears: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    if (this.ShowType == "Wallet") {
      this.colspanss = 5;
      this.ShowFinicialYears();
      this.SubmitActionForm();
      this.UrlCalls = "GemsViewDetailsAgent";
    } else {
      this.colspanss = 3;
      this.GetDetails();
      this.UrlCalls = "GemsViewDetails";
    }
  }
  ShowFinicialYears() {
    this.api.IsLoading();
    this.api.HttpGetType("b-crm/Filter/GetOnlySessionYears").then(
      (result) => {
        this.api.HideLoading();
        if (result["Status"] == true) {
          // console.log(result);

          this.SR_Session_Year = result["Data"]["SR_Session_Year"];
        } else {
          this.api.Toast("Warning", result["Message"]);
        }
      },
      (err) => {
        this.api.HideLoading();
        this.api.Toast(
          "Warning",
          "Network Error : " + err.name + "(" + err.statusText + ")"
        );
      }
    );
  }

  CloseModel(): void {
    this.dialogRef.close({
      Status: "Model Close",
    });
  }
  get FC_6() {
    return this.ActionForm.controls;
  }

  SubmitActionForm() {
    this.isSubmitted = true;
    if (this.ActionForm.invalid) {
      return;
    } else {
      var fields = this.ActionForm.value;
      const formData = new FormData();
      this.Session_Year = fields["FinancialYears"][0]["Id"];

      this.GetDetails();
    }
  }

  GetDetails() {
    if (this.ShowType == "Wallet") {
      var UrlCalls = "GemsViewDetailsAgent";
    } else {
      var UrlCalls = "GemsViewDetails";
    }

    this.api.IsLoading();
    this.api
      .HttpGetType(
        "PrimeAgent/" +
          UrlCalls +
          "?Agent_Id=" +
          this.Agent_Id +
          "&Login_Id=" +
          this.api.GetUserData("Id") +
          "&Session_Year=" +
          this.Session_Year
      )
      .then(
        (result) => {
          if (result["Status"] == true) {
            this.DataAr = result["Data"];
          } else {
          }
          this.api.HideLoading();
        },
        (err) => {
          this.api.HideLoading();
        }
      );
  }

  ViewGemsRemark(v) {
    const dialogRef = this.dialog.open(GemsDetailsViewRemarkComponent, {
      width: "30%",
      height: "30%",
      data: { Remark: v },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      // console.log(result);
    });
  }
}
