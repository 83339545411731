import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-request',
  templateUrl: './survey-request.component.html',
  styleUrls: ['./survey-request.component.css']
})
export class SurveyRequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
