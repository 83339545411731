import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../providers/api.service";
import { Router, ActivatedRoute } from "@angular/router";

import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DetailsRmBoxComponent } from "../details-rm-box/details-rm-box.component";
import { empty } from "rxjs";
import { ViewDashboardPoupupComponent } from "../../modals/view-dashboard-poupup/view-dashboard-poupup.component";
import { currentId } from "async_hooks";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  Dataarr: any = [];
  dataResult: any = [];
  DashboardValue: any = [];
  UnderTrainingAgentResult: any = [];
  AgentTrainingType: any = [];
  Dataarr1: any;
  DashboardValue1: any;
  LoginType: string;
  Life_Training_Status: any;
  PosStatus: string;
  FetchData: any;
  RmData: any;
  TeleRmData: any;
  LoginId: any;
  TotalLObGraph: any = 0;
  TotalPolicyGraph: any;
  PolicyChartLabels: string[];
  RevenueChartLabels: string[];
  TotalRevenueGraph: any;
  OnlineQuotes: any = 0;
  OnlineQuoteConverted: any = 0;
  OnlineQuoteRatio: any = 0;

  ShowLoaderQuoteRatio: string = "No";
  ShowLoaderPolicyGraph: string = "No";
  ShowLoaderLobGraph: string = "No";
  ShowLoaderEarningGraph: string = "No";
  ViewKnowledgeBaseData: any;
  OfflineQuotes: any = 0;
  OfflineQuoteConverted: any = 0;
  OfflineQuoteRatio: any = 0;
  TotalConversionRatio: any = 0;
  EarningTransfered: any = 0;
  EarningPending: any = 0;
  EarningTotal: any = 0;
  dataArrayOnlineQuote: any = 0;
  DataDashboard: any = [];
  DepartmentIdLoginUser: any;
  Ops_Data_Dashboard: string;
  OpsCurrentDay: any;
  OpsLastDay: any;

  // PolicyChartData: { data: any; label: string; }[];
  // RevenueChartData: { data: any; label: string; }[];

  constructor(
    public api: ApiService,
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  public PolicyChartType = "bar";
  public RevenueChartType = "bar";

  public barChartLegend = false;

  public pieChartLabels = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  pieChartLabels2 = ["1", "2", "3", "4", "5", "6", "7"];
  pieChartData = [0, 0, 0, 0, 0, 0, 0];
  pieChartType = "pie";

  ngOnInit() {
    this.DataDashboard = [];

    this.Life_Training_Status = 1;

    this.GetDashBoardEmployeeWise(516);
    this.GetDashBoardEmployeeWise(517);
    this.GetDashBoardEmployeeWise(518);
    this.GetDashBoardEmployeeWise(519);
    this.GetDashBoardEmployeeWise(520);
    this.GetDashBoardEmployeeWise(521);
    this.GetDashBoardEmployeeWise(522);
    this.GetDashBoardEmployeeWise(523);

    this.SquareData();
    this.BMSData();

    this.LobGraph();
    this.PolicyGraph();
    this.EarningGraph();
    this.QuotesRatio();

    this.GetKnowledgeBaseDetails();
    // console.log(this.api.GetPosType());

    this.LoginType = this.api.GetUserType();
    this.LoginId = this.api.GetUserData("Id");
    this.DepartmentIdLoginUser = this.api.GetUserData("department");

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    console.log(this.DepartmentIdLoginUser);
    // this.DepartmentIdLoginUser = 10;
    if (this.DepartmentIdLoginUser == 10) {
      this.OperationCurrentDayPerformanceReport(Date(), "CurrentDay");
      this.OperationCurrentDayPerformanceReport(yesterday, "Yesterday");
    }

    if (this.LoginType == "agent") {
      this.GetRmTeleRmDetails();

      this.PosStatus = this.api.GetUserData("pos_status");
      this.Life_Training_Status = this.api.GetUserData("Life_Training_Status");

      if (this.PosStatus == "2") {
        this.Life_Training_Status = this.Life_Training_Status;
      }
    }

    //alert(btoa('Life'));
  }

  OperationCurrentDayPerformanceReport(Date: any, Day) {
    const formData = new FormData();

    this.Ops_Data_Dashboard = "";
    // this.LoginId = 211;
    formData.append("User_Id", this.LoginId);
    formData.append("User_Type", this.LoginType);
    if (Day == "CurrentDay") {
      formData.append("OpsEmpId", this.LoginId);
    }
    formData.append("Date", Date);
    formData.append("Day", Day);

    this.api.IsLoading();
    this.api
      .HttpPostType("TodayDashboard/GetOperationRightsEmployee", formData)
      .then(
        (result: any) => {
          this.api.HideLoading();
          if (result["status"] == 1) {
            if (Day == "CurrentDay") {
              this.OpsCurrentDay = result["Data"];
            }

            if (Day == "Yesterday") {
              this.OpsLastDay = result["Data"];
            }

            // this.OpsRmData = result["OpsRmData"];
            // this.TeleRmData = result["TeleRmData"];
          } else {
            this.api.Toast("Warning", result["msg"]);
          }
        },
        (err) => {
          this.api.HideLoading();
          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  CheckStatusLife() {
    throw new Error("Method not implemented.");
  }

  UpDateOperationDepartmentPerformance() {}

  RedirectToWEbs(Url) {
    var UserDatas = this.api.GetUserData("Id");
    var GetUserType = this.api.GetUserType();

    // alert( UserDatas);
    // alert( GetUserType);
    // return false;
    let a = document.createElement("a");
    a.target = "_blank";
    if (GetUserType == "employee") {
      a.href =
        this.api.ReturnWebUrl() +
        "/Prequotes/SetSessionEmployee/login/" +
        btoa(UserDatas) +
        "?ReturnUrl=" +
        Url;
    } else {
      a.href =
        this.api.ReturnWebUrl() +
        "/agents/check/" +
        btoa(UserDatas) +
        "/" +
        btoa(GetUserType) +
        "/login?ReturnUrl=" +
        Url;
    }

    // alert(a);
    // return false;
    a.click();
  }

  BMSData() {
    //  return false;
    this.api.IsLoading();

    this.api
      .HttpGetSOCKET_ENDPOINT(
        "Reports/" +
          this.api.GetUserData("Id") +
          "/" +
          this.api.GetUserType() +
          "/Bms"
      )
      .then(
        (result) => {
          var data = JSON.parse(result[0].body);
          if (data.status == 1) {
            this.api.HideLoading();

            this.Dataarr = data.data;
          } else {
            this.api.Toast("Warning", data.msg);
          }
        },
        (err) => {
          this.api.HideLoading();
          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  SquareData() {
    this.api.IsLoading();
    this.api
      .HttpGetSOCKET_ENDPOINT(
        "Reports/" +
          this.api.GetUserData("Id") +
          "/" +
          this.api.GetUserType() +
          "/Web"
      )
      .then(
        (result) => {
          var data = JSON.parse(result[0].body);

          if (data.status == 1) {
            this.api.HideLoading();

            // console.log(data.data);
            this.Dataarr1 = data.data;
            this.DashboardValue = data.Dashboard;
            this.UnderTrainingAgentResult = data.ResultAgent;
            this.AgentTrainingType = data.ResultAgent;

            if (this.api.GetDashboardLoginPoupup() == "1") {
              if (
                this.api.GetUserType() == "agent" &&
                this.api.GetUserData("pos_status") == 2 &&
                this.Life_Training_Status == "0"
              ) {
                this.ViewDashboardPoupup();
              }
            }

            if (result["Redirect"] == "ExamStart") {
              this.router.navigate([
                "Agent/ExamStart/" + btoa(this.AgentTrainingType),
              ]);
            } else if (result["Redirect"] == "Training") {
              this.router.navigate([
                "Agent/Training/" + btoa(this.AgentTrainingType),
              ]);
            } else if (result["Redirect"] == "ExamResult") {
              this.router.navigate([
                "Agent/ExamResult/" + btoa(this.AgentTrainingType),
              ]);
            }
          } else {
            this.api.HideLoading();
            this.api.Toast("Warning", data.msg);
          }
        },
        (err) => {
          this.api.HideLoading();
          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  Clicks(Type: any, Result: any) {
    if (Result != null || (Result != "" && Type != "")) {
      this.router.navigate(["Agent/ExamResult/" + btoa(Type)]);
    } else if (Result == null || (Result == "" && Type != "")) {
      this.router.navigate(["Agent/ExamStart/" + btoa(Type)]);
    }
  }

  Clicks2(Type: any, TrainingType: any) {
    this.router.navigate(["Agent/Training/" + btoa(TrainingType)]);
  }

  Logout() {
    this.router.navigate(["Logoutweb"]);
  }

  GetRmDetails(): void {
    var id = this.api.GetUserData("Id");

    // // console.log(id);/

    const dialogRef = this.dialog.open(DetailsRmBoxComponent, {
      width: "40%",
      height: "40%",
      disableClose: true,

      data: { Id: id },
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      // console.log(result);
    });
  }

  ViewDashboardPoupup(): void {
    const dialogRef = this.dialog.open(ViewDashboardPoupupComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      // console.log(result);
      localStorage.setItem("LoadingDashboardPoupup", "0");
    });
  }

  GetRmTeleRmDetails() {
    const formData = new FormData();

    formData.append("User_Id", this.LoginId);
    formData.append("User_Type", this.LoginType);

    this.api.IsLoading();

    this.api.HttpPostType("MyPos/GetRmTeleRmDetails", formData).then(
      (result) => {
        this.api.HideLoading();
        if (result["status"] == 1) {
          this.FetchData = result["Data"];
          this.RmData = result["RmData"];
          this.TeleRmData = result["TeleRmData"];
        } else {
          this.api.Toast("Warning", result["msg"]);
        }
      },
      (err) => {
        this.api.HideLoading();
        this.api.Toast(
          "Warning",
          "Network Error : " + err.name + "(" + err.statusText + ")"
        );
      }
    );
  }

  LobGraph() {
    const formData = new FormData();

    formData.append("User_Id", this.LoginId);
    formData.append("User_Type", this.LoginType);

    this.api.IsLoading();

    this.api
      .HttpGetSOCKET_ENDPOINT(
        "Dashboard/" +
          this.api.GetUserData("Id") +
          "/" +
          this.api.GetUserType() +
          "/Lob"
      )
      .then(
        (result) => {
          var data = JSON.parse(result[0].body);

          if (data.status == 1) {
            this.api.HideLoading();

            this.TotalLObGraph = data.TotalCount;
            var newDataId = data.data.split(",");
            var newDataValue = data.DataValue.split(",");
            this.pieChartLabels2 = newDataValue;
            this.pieChartData = newDataId;
          } else {
            this.api.Toast("Warning", data.msg);
            this.api.HideLoading();
          }
        },
        (err) => {
          this.api.HideLoading();

          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  EarningGraph() {
    const formData = new FormData();
    this.ShowLoaderEarningGraph = "Yes";
    this.api.IsLoading();

    formData.append("User_Id", this.LoginId);
    formData.append("User_Type", this.LoginType);

    this.api
      .HttpGetSOCKET_ENDPOINT(
        "Dashboard/" +
          this.api.GetUserData("Id") +
          "/" +
          this.api.GetUserType() +
          "/Revenue"
      )
      .then(
        (result) => {
          var data = JSON.parse(result[0].body);

          if (data.status == 1) {
            this.ShowLoaderEarningGraph = "No";

            this.TotalRevenueGraph = data.TotalCount;
            var newDataIdRevenue = data.data.split(",");
            var newDataValueRevenue = data.DataValue.split(",");
            this.RevenueChartLabels = newDataValueRevenue;

            this.RevenueChartData = [
              { data: newDataIdRevenue, label: "Revenue" },
            ];

            this.api.HideLoading();
          } else {
            this.api.Toast("Warning", data.msg);
            this.api.HideLoading();
          }
        },
        (err) => {
          this.api.HideLoading();

          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  PolicyGraph() {
    this.api.IsLoading();

    const formData = new FormData();

    formData.append("User_Id", this.LoginId);
    formData.append("User_Type", this.LoginType);

    // this.api.IsLoading();
    this.api
      .HttpGetSOCKET_ENDPOINT(
        "Dashboard/" +
          this.api.GetUserData("Id") +
          "/" +
          this.api.GetUserType() +
          "/Policy"
      )
      .then(
        (result) => {
          var data = JSON.parse(result[0].body);

          if (data.status == 1) {
            // this.api.HideLoading();

            this.ShowLoaderPolicyGraph = "No";

            this.TotalPolicyGraph = data.TotalCount;
            var newDataIdPolicy = data.data.split(",");
            var newDataValuePolicy = data.DataValue.split(",");

            this.PolicyChartLabels = newDataValuePolicy;
            this.PolicyChartData = [
              { data: newDataIdPolicy, label: "Monthly Business" },
            ];
            this.api.HideLoading();
          } else {
            this.ShowLoaderPolicyGraph = "No";

            this.api.Toast("Warning", data.msg);
            this.api.HideLoading();
          }
        },
        (err) => {
          this.api.HideLoading();
          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  QuotesRatio() {
    const formData = new FormData();
    this.api.IsLoading();

    formData.append("User_Id", this.LoginId);
    formData.append("User_Type", this.LoginType);

    // this.api.IsLoading();
    this.api
      .HttpGetSOCKET_ENDPOINT(
        "Dashboard/" +
          this.api.GetUserData("Id") +
          "/" +
          this.api.GetUserType() +
          "/Quote"
      )
      .then(
        (result) => {
          var data = JSON.parse(result[0].body);

          if (data.status == 1) {
            // this.api.HideLoading();
            this.OnlineQuotes = data.OnlineQuotes;
            this.OnlineQuoteConverted = data.OnlineQuoteConverted;
            this.OnlineQuoteRatio = data.OnlineQuoteRatio;

            this.OfflineQuotes = data.OfflineQuotes;
            this.OfflineQuoteConverted = data.OfflineQuoteConverted;
            this.OfflineQuoteRatio = data.OfflineQuoteRatio;

            this.TotalConversionRatio = data.TotalConversionRatio;

            this.dataArrayOnlineQuote = data.Data;

            this.GetEarning();

            this.api.HideLoading();
          } else {
            this.api.Toast("Warning", data.msg);
            this.api.HideLoading();
          }
        },
        (err) => {
          // this.api.HideLoading();
          this.api.HideLoading();

          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
        }
      );
  }

  GetEarning() {
    this.api.IsLoading();

    const formData = new FormData();
    formData.append("UserId", this.api.GetUserData("Id"));
    formData.append("UserType", this.api.GetUserType());
    formData.append("dashboard_status", "1");

    //crm.squareinsurance.in/backuplivelife/api/dashboard/Earningvalucehek/17564/agent?User_Id=54&User_Type=employee
    https: this.api.IsLoading();
    this.api
      .HttpGetType(
        "dashboard/Earningvalucehek/" +
          this.api.GetUserData("Id") +
          "/" +
          this.api.GetUserType() +
          "?User_Id=54&User_Type=employee"
      )
      .then(
        (result) => {
          this.api.HideLoading();
          // console.log(result);

          if (result["status"] == 1) {
            this.EarningTransfered = result["Transfferd"];
            this.EarningPending = result["Pending"];
            this.EarningTotal = result["Total"];
          } else {
            const msg = "msg";
            //alert(result['message']);
            this.api.Toast("Warning", result["msg"]);
          }
        },
        (err) => {
          // Error log
          // // console.log(err);
          this.api.HideLoading();
          const newLocal = "Warning";
          this.api.Toast(
            newLocal,
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
          //this.api.ErrorMsg('Network Error :- ' + err.message);
        }
      );
  }

  //ACCEPT REQUEST
  GetKnowledgeBaseDetails() {
    this.api.IsLoading();

    const formData = new FormData();
    formData.append("UserId", this.api.GetUserData("Id"));
    formData.append("UserType", this.api.GetUserType());
    formData.append("dashboard_status", "1");

    this.api.IsLoading();
    this.api
      .HttpPostType("KnowledgeBase/ViewKnowledgeBaseDetails", formData)
      .then(
        (result) => {
          this.api.HideLoading();
          // console.log(result);

          if (result["status"] == true) {
            // this.api.Toast("Success", result["msg"]);
            this.ViewKnowledgeBaseData = result["data"];
            // this.Reload();
          } else {
            const msg = "msg";
            //alert(result['message']);
            this.api.Toast("Warning", result["msg"]);
          }
        },
        (err) => {
          // Error log
          // // console.log(err);
          this.api.HideLoading();
          const newLocal = "Warning";
          this.api.Toast(
            newLocal,
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
          //this.api.ErrorMsg('Network Error :- ' + err.message);
        }
      );
  }

  public PolicyChartData = [
    { data: [0, 1, 2, 3, 4, 5, 6, 7], label: "Monthly Business" },
  ];

  public RevenueChartData = [
    { data: [0, 1, 2, 3, 4, 5, 6, 7], label: "Revenue" },
  ];

  GetDashBoardEmployeeWise(Id: any) {
    this.api
      .HttpGetType(
        "Dashboard/GetDashBoardEmployeeWise?Id=" +
          Id +
          "&User_Id=" +
          this.api.GetUserData("Id") +
          "&User_Type=" +
          this.api.GetUserType() +
          "&User_Code=" +
          this.api.GetUserData("Code") +
          "&Type=Claim"
      )
      .then(
        (result: any) => {
          //this.api.HideLoading();
          if (result["status"] == true) {
            if (result["Data"] != "") {
              this.DataDashboard = this.DataDashboard.concat(result["Data"]);

              // this.DataDashboard = this.DataDashboard.concat();

              // this.DataDashboard.push(result["Data"]);
            }
            //this.api.Toast('Success',result['msg']);
            // this.DataDashboard = this.DataDashboard.concat();

            // this.DataDashboard.push(result["Data"]);
          } else {
            //alert(result['message']);
            //this.api.Toast('Warning',result['msg']);
          }
        },
        (err) => {
          // Error log
          //// console.log(err);
          this.api.HideLoading();
          this.api.Toast(
            "Warning",
            "Network Error : " + err.name + "(" + err.statusText + ")"
          );
          //this.api.ErrorMsg('Network Error :- ' + err.message);
        }
      );
  }




  // yuvraj code 
  convertToProperCase(text: string): string {
    return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
}
